export enum EInputType {
  TEXT = "TEXT",
  MULTI_OPTION = "MULTI_OPTION",
  RADIO = "RADIO",
  NUMBER = "NUMBER",
  EMAIL = "EMAIl",
  PHONE_NUMBER = "PHONE_NUMBER",
}

export type TQuestionBasicFeild = {
  id: string;
  question: string;
  required?: boolean;
};
export interface IRadioQuestion {
  options: string[];
  type: EInputType.RADIO;
  isConditional?: boolean;
  conditionalQuestions?: any[];
  othersoption?: boolean;
}

export interface ICheckboxQuestion {
  options: string[];
  type: EInputType.MULTI_OPTION;
  isConditional?: boolean;
  conditionalQuestions?: any[];
  othersoption?: boolean;
}

export interface ITextQuestion {
  type: EInputType.TEXT;
  isConditional?: boolean;
  conditionalQuestions?: any[];
}

export interface IEmailQuestion {
  type: EInputType.EMAIL;
  isConditional?: boolean;
  conditionalQuestions?: any[];
}

export interface INumberQuestion {
  type: EInputType.NUMBER;
  isConditional?: boolean;
  conditionalQuestions?: any[];
}

export interface IEmailConfig {
  emailTemplate: string;
  subject: string;
  delayInMinutes: number;
}

export type TFormField = TQuestionBasicFeild &
  (
    | IRadioQuestion
    | ICheckboxQuestion
    | ITextQuestion
    | IEmailQuestion
    | INumberQuestion
  );
export interface IFormConfiguration {
  courseKey: string;
  title: string;
  subtitle: string;
  redirectUrl?: string;
  phoneCode?: string;
  mentoredBy: {
    name: string;
    image: string;
  }[];
  fbTrackingPixelCode?: number | string;
  formFields: TFormField[];
  sheetId?: string;
  emailConfig: IEmailConfig[];
  videoUrl?: string;
  startPageQuestion?: TQuestionBasicFeild & IRadioQuestion;
  webinarId?: number;
}

export interface IChainingQuestionsOptions {
  parentQuestionId: string;
  conditionalQuestions: TFormField[];
  value: string;
}

export interface IChainingRadioQuestion {
  options: string[] | IChainingQuestionsOptions[];
  type: EInputType.RADIO;
  isConditional?: boolean;
  conditionalQuestions?: TFormField[];
  value?: string;
  othersoption?: boolean;
}

//webinar types
export type Webinar = {
  id: number;
  name: string;
  duration: number;
  videoDuration: number;
  videoStart: number;
  videoEnd: number;
  fbqPixelId: string | null;
  // permanentSlots: Prisma.JsonArray;
  createdAt: Date;
  updatedAt: Date;
  videoUuid: string;
  immediateSlotId: number | null;
};

export type ImmediateSlot = {
  id: number;
  // activeMethod: ImmediateSlotSelectionMethod;
  time: string;
  // range: Prisma.JsonObject;
  // values: Prisma.JsonArray;
  createdAt: Date;
  updatedAt: Date;
};

export type WebinarSchedule = {
  id: number;
  startTime: Date;
  expireAt: string;
  expirationDelay: number;
  // type: WebinarScheduleType;
  createdAt: Date;
  updatedAt: Date;
  webinarId: number;
};

export type TWebinarScheduleWithExpiryDetails = WebinarSchedule & {
  isExpired: boolean;
  isEnded: boolean;
};

export const webinarScheduleType = {
  immediateSlot: "immediateSlot",
  permanentSlot: "permanentSlot",
  recurringSchedule: "recurringSchedule",
  particularTime: "particularTime",
  interval: "interval",
};

export type WebinarScheduleType = keyof typeof webinarScheduleType;

export interface ICountryDetails {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}