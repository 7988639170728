import "./App.scss";
import Header from "components/Header";
import { Outlet, Route, Routes } from "react-router-dom";
import FluentForm from "containers/FluentForm";
import SuccessfullyCompleted from "components/SuccessfullyCompleted";
import AngelInvestment from "containers/programs/AngelInvesment";
import React, { Suspense } from "react";
import GlobalContext from "store/GlobalContext";

const Custom = React.lazy(() => import("containers/Custom"));
const Admin = React.lazy(() => import("containers/Admin"));
const CreateForm = React.lazy(() => import("containers/Admin/CreateForm"));
const ViewFormContainer = React.lazy(
  () => import("containers/Admin/ViewFormContainer")
);

const ContainerWithHeader = () => (
  <>
    <Header />
    <div className="form">
      <Outlet />
    </div>
  </>
);

function App() {
  return (
    <div className="App">
      <Suspense fallback={<>Loading...</>}>
        <GlobalContext>
          <Routes>
            <Route path="/success" element={<SuccessfullyCompleted />} />
            <Route path="/custom/:course" element={<Custom />} />
            <Route path="/custom/:course/:id" element={<Custom />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/create" element={<CreateForm />} />
            <Route path="/admin/edit/:courseKey" element={<CreateForm />} />
            <Route path="/admin/:courseKey" element={<ViewFormContainer />} />
            <Route path="/" element={<ContainerWithHeader />}>
              <Route path="/programs/:course" element={<AngelInvestment />} />
              <Route path="/:course" element={<FluentForm />} />
            </Route>
          </Routes>
        </GlobalContext>
      </Suspense>
    </div>
  );
}

export default App;
