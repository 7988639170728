import {
  Button,
  ButtonProps,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import { Box, styled } from "@mui/system";

import { FormEvent, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { emailRegex } from "utils/constants";

import {
  coursesList,
  experinceList,
  getTitleAndRedirectUrl,
} from "./constants";

import "./fluentForm.scss";

interface IFormData {
  name: string;
  email: string;
  mobile: string;
  companyName: string;
  jobTitle: string;
  yearsOfExperience: string;
  otherCourses: string[];
  linkedInProfileLink: string;
  twitterProfileLink: string;
  learnerCommunity: string;
  financialInstrumentsInterestedIn: string[];
  currentOccupation: string;
  amountWillingToInvest: string;
  howSoon: string;
  whichIndustries: string[];
}

const defaultFormValues: IFormData = {
  name: "",
  email: "",
  mobile: "",
  companyName: "",
  jobTitle: "",
  yearsOfExperience: "",
  otherCourses: [],
  linkedInProfileLink: "",
  twitterProfileLink: "",
  learnerCommunity: "Yes",
  financialInstrumentsInterestedIn: [],
  currentOccupation: "",
  amountWillingToInvest: "",
  howSoon: "",
  whichIndustries: [],
};

interface IButtonProps extends ButtonProps {
  positionCenter?: boolean;
}

export const ColorButton = styled(Button)<IButtonProps>(
  ({ positionCenter = true }) => ({
    color: "white",
    fontWeight: "bold",
    display: "flex",
    justifyContent: positionCenter ? "space-around" : "space-between",

    backgroundColor: "#000000",
    boxShadow: "0px 2px 2px 2px rgba(204, 204, 204, 0.1)",
    "&:hover": {
      backgroundColor: "#02454a",
    },
    "> span:only-child": {
      margin: "0 auto",
    },
    "&[disabled]": {
      backgroundColor: "grey",
      color: "white",
    },
  })
);

const FluentForm = () => {
  const { course } = useParams<{ course: string }>();

  const { title, redirectUrl } = getTitleAndRedirectUrl(course ?? "");

  const navigate = useNavigate();

  const [form, setFormData] = useState(defaultFormValues);
  const [fillRequiredFields, setFillRequiredFields] = useState(false);
  const ref = useRef(form);

  const updateData = (field: string, value: string | string[]) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    ref.current = {
      ...ref.current,
      [field]: value,
    };
  };

  const postData = () => {
    fetch(
      "https://webhooks.integrately.com/a/webhooks/c80597b1af0f49baa9ea9b1cf56f0340",
      {
        method: "POST",
        body: JSON.stringify({ ...ref.current, course }),
      }
    );
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    postData();
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      navigate("/success");
    }
  };

  const onBlur = () => {
    if (
      !fillRequiredFields &&
      emailRegex.test(form.email) &&
      form.name.trim().length > 0
    ) {
      setFillRequiredFields(true);
      postData();
    }
  };

  return (
    <div className="fluent-form">
      <div className="form-header">
        <h2>
          {title
            ? `Register to our exclusive Live Workshop for FREE here: ${title}`
            : "Fill the form below to get early access to the course for FREE!"}
        </h2>
      </div>
      <form className="form" onSubmit={onSubmit}>
        <TextField
          value={form.name}
          onChange={(e) => updateData("name", e.target.value)}
          required
          id="outlined-required"
          label="Name"
          type="text"
          InputProps={{ inputProps: { autocomplete: "name" } }}
        />
        <TextField
          value={form.email}
          onChange={(e) => updateData("email", e.target.value)}
          required
          id="outlined-required"
          label="Email"
          type="email"
        />
        <TextField
          value={form.mobile}
          onChange={(e) => updateData("mobile", e.target.value)}
          required={course === "wmkaustubh" || !!title}
          id="outlined-required"
          label="Phone/Mobile"
          type="tel"
          onFocus={onBlur}
          onBlur={onBlur}
          InputProps={{ inputProps: { min: 1, autocomplete: "tel" } }}
        />

        {fillRequiredFields && (
          <>
            <TextField
              value={form.companyName}
              onChange={(e) => updateData("companyName", e.target.value)}
              id="outlined-required"
              label="Company Name"
            />
            <TextField
              value={form.jobTitle}
              onChange={(e) => updateData("jobTitle", e.target.value)}
              id="outlined-required"
              label="Job Title"
            />
            <FormControl fullWidth>
              <InputLabel id="years-of-experience">
                Years of Experience
              </InputLabel>
              <Select
                labelId="years-of-experience"
                id="demo-simple-select"
                value={form.yearsOfExperience}
                label="Years of Experience"
                onChange={(e) =>
                  updateData("yearsOfExperience", e.target.value)
                }
              >
                {experinceList.map((item) => {
                  return (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="other-courses">
                What are some other topics you would be interested in
              </InputLabel>
              <Select
                labelId="other-courses"
                id="demo-simple-select"
                multiple
                value={form.otherCourses}
                label="What are some other topics you would be interested in"
                onChange={(e: SelectChangeEvent<string[]>) => {
                  const {
                    target: { value },
                  } = e;

                  updateData(
                    "otherCourses",
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {coursesList.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      <Checkbox
                        checked={form.otherCourses.indexOf(item) > -1}
                      />
                      <ListItemText primary={item} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Would you like to be added to our learner’s community
              </FormLabel>
              <RadioGroup
                row
                aria-label="gender"
                name="row-radio-buttons-group"
                value={form.learnerCommunity}
                onChange={(_, value) => updateData("learnerCommunity", value)}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            <TextField
              value={form.linkedInProfileLink}
              onChange={(e) =>
                updateData("linkedInProfileLink", e.target.value)
              }
              id="outlined-required"
              label="LinkedIn Profile Link (So the mentor can connect)"
            />
            <TextField
              value={form.twitterProfileLink}
              onChange={(e) => updateData("twitterProfileLink", e.target.value)}
              id="outlined-required"
              label="Twitter Profile Link"
            />
          </>
        )}
        <ColorButton type="submit">Submit</ColorButton>
      </form>
    </div>
  );
};

export default FluentForm;
