export const experinceList = [
  "I am student",
  "0-2 Years",
  "3-5 Years",
  "6-10 Years",
  "10+ Years",
];

export const coursesList = [
  "Marketing",
  "Tech",
  "Product",
  "Content",
  "Data Analytics",
  "Sales",
  "Design",
  "Wealth Management",
  "Finance & Investment ",
  "Operations & Project Management ",
  "AI/ML",
];

export const fluentFormConfiguration: {
  [key in string]: {
    title: string;
    redirectUrl: string;
  };
} = {
  test: {
    title: "test",
    redirectUrl: "https://www.google.com",
  },
  "performance-marketing-hitesh": {
    title: "Performance Marketing Hitesh",
    redirectUrl: "https://app.growthschool.io/thankyou-masterclass-1-4/",
  },
  "management-consulting-dhairya": {
    title: "Management consulting-Dhairya",
    redirectUrl:
      "https://app.growthschool.io/thankyou-lead-consulting-dhariya-webinar/",
  },
  "ux-research-shrut-kirti": {
    title: "UX Research-Shrut Kirti",
    redirectUrl: "https://app.growthschool.io/thankyou-lead-uxshrut-mws/",
  },
  "gamification-vinayak": {
    title: "Gamification-Vinayak",
    redirectUrl: "https://app.growthschool.io/thankyou-lead-growthvinayak-mws/",
  },
  "web3-preetam": {
    title: "Web3-Preetam",
    redirectUrl: "https://app.growthschool.io/thankyou-lead-web3preetam-mws/",
  },
  "data-structure-jaideep": {
    title: "Data Structure-Jaideep",
    redirectUrl: "https://app.growthschool.io/thankyou-lead-dsjaideep-mws/",
  },
  "ui-ux-vatsala": {
    title: "UI/UX-Vatsala",
    redirectUrl: "https://app.growthschool.io/thankyou-lead-uivatsala-mws/",
  },
  "marketing-analytics-kruba": {
    title: "Marketing Analytics-Kruba",
    redirectUrl:
      "https://app.growthschool.io/thankyou-lead-analtyicskruba-mws/",
  },
  "cltv-abhishek": {
    title: "CLTV-Abhishek",
    redirectUrl: "https://app.growthschool.io/thankyou-lead-cltvabhishek-mws/",
  },
  "product-design-milan": {
    title: "Product Design Milan",
    redirectUrl: "https://app.growthschool.io/thankyou-lead-milan-webinar/",
  },
};

export const getTitleAndRedirectUrl = (id: string) => {
  const configuration = fluentFormConfiguration[id];
  if (configuration) {
    return configuration;
  } else {
    return { title: undefined, redirectUrl: undefined };
  }
};
