import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "containers/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import * as Sentry from "@sentry/react";
import { Location, Action } from "@sentry/react/types/types";

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#025352",
      contrastText: "white",
    },
  },
});

Sentry.init({
  dsn: "https://120279ca3fa6a4133a948a62cc477cd8@o4505843707674624.ingest.us.sentry.io/4507305141665792",
  integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation: function (): Location {
        throw new Error("Function not implemented.");
      },
      useNavigationType: function (): Action {
        throw new Error("Function not implemented.");
      },
      createRoutesFromChildren: function (children: JSX.Element[]) {
        throw new Error("Function not implemented.");
      },
      matchRoutes: function (routes: any, location: Location, basename?: string | undefined): any[] | null {
        throw new Error("Function not implemented.");
      }
    }),
    Sentry.replayIntegration()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
});

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
