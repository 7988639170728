export const fbPixelCodeMap: { [key: string]: string } = {
  wmkaustubh: "1026081364616098",
};

export const financialInstrumentsInterestedIn = [
  "None",
  "Bank/Fixed Deposits",
  "Mutual Funds",
  "Equity Shares",
  "Real Estate",
  "Porfolio Management Services",
  "Hedge Funds",
  "Start-Ups (Angel Rounds)",
];

export const currentOccupation = [
  "Business - Promoter",
  "Business - Owner",
  "Business - Employee",
  "Self Employed/Freelancer",
  "Salaried - Senior Management",
  "Salaried - Mid Management",
  "Salaried - Employee",
];

export const amountWillingToInvest = [
  "1 - 5 Lakhs",
  "5 - 10 Lakhs",
  "10 - 25 Lakhs",
  "Greater than 25 Lakhs",
  "I don't want to disclose",
];
export const howSoon = [
  "Within 3 months",
  "3 - 6 Months from now",
  "6 - 12 Months from now",
  "More than 12 Months from now",
];
export const whichIndustries = [
  "Fin-tech",
  "Ed-Tech",
  "Enterprise Solutions",
  "Food-tech",
  "Health-Tech",
  "Agri-Tech",
  "Media and Entertainment",
  "Logistics",
  "Others",
];
