export const fbPixelCodeMap: { [key: string]: string } = {
  wmkaustubh: "1026081364616098",
};

export const injectFBPixelCodePageViews = (code: string | number) => {
  const { fbq } = window as any;

  if (!fbq) {
    const script = document.createElement("script");
    script.innerHTML = `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${code}');
    fbq('track', 'PageView');`;

    document.head.appendChild(script);
  }
};

export const trackFBLead = () => {
  const { fbq } = window as any;
  if (fbq) {
    fbq("track", "Lead");
  }
};
