import {
  EInputType,
  IRadioQuestion,
  TQuestionBasicFeild,
} from "hooks/data/types";

export const emailRegex = /\S+@\S+\.\S+/;

export const API_ENPOINT =
  "https://1qzku2tw29.execute-api.ap-south-1.amazonaws.com/prod";

export const defaultEmailFormat = `<p>Hey {{name}},</p>

<p>Hope that you are doing good.</p>

<p>We noticed that you haven’t completed the application form.</p>

<p>You still have 2 hours to complete the form until we close the application.</p>

<p>This program will help you open some closed doors and undiscover some amazing opportunities.</p>

<p>So, what are you waiting for?</p>

<p>Complete the application form now!</p>

<p>
  <a href="{{formlink}}" target="_blank" style="text-decoration:none">{{formlink}}</a>
</p>

<p>Here’s to growth!</p>

<p>Cheers,<br>GrowthSchool</p>
`;

export const defaultEmailSubject = `⏰Only 2 hours until the application form expires.`;

export const defaultStartPageQuestion: TQuestionBasicFeild & IRadioQuestion = {
  options: [
    "0 years",
    "0-3 years",
    "3-6 years",
    "6-10 years",
    "More than 10 years",
  ],
  id: "How-many-years-of-experience-do-you-have-in-Product-(or)-Marketing?",
  type: EInputType.RADIO,
  question:
    "How many years of experience do you have in Product (or) Marketing?",
  required: true,
};

export const mixPanelTokenStaging = "9fe167918f283d6de7f88268be595a9b";
export const mixPanelTokenProduction = "5059bb663c207eecebbdcf902625dd8a";

export const webHooksUrls: { [key: string]: string } = {
  "data-science-lp-application-partial":
    "https://hooks.slack.com/services/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",
  "data-science-lp-application-full":
    "https://hooks.slack.com/services/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",
  "full-stack-dev-lp-application-partial":
    "https://hooks.slack.com/services/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",
  "full-stack-dev-lp-application-full":
    "https://hooks.slack.com/services/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",
  "full-stack-dev-aw-application-partial":
    "https://hooks.slack.com/services/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",
  "full-stack-dev-aw-application-full":
    "https://hooks.slack.com/services/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",
  "performance-marketing-deepan-partial":
    "https://hooks.slack.com/services/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",
  "performance-marketing-deepan-full":
    "https://hooks.slack.com/services/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",
  "performance-marketing-mayank-partial":
    "https://hooks.slack.com/services/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",
  "performance-marketing-mayank-full":
    "https://hooks.slack.com/services/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",
};

export const selectedChannels = [
  "data-science-lp-application",
  "full-stack-dev-lp-application",
  "full-stack-dev-aw-application",
  "performance-marketing-deepan",
  "performance-marketing-mayank"
];

