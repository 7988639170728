import React, { createContext, useContext, useReducer } from "react";

export const GlobalContextState = createContext<IGlobalState | null>(null);
export const GlobalDispatchState =
  createContext<React.Dispatch<TActions> | null>(null);

interface IGlobalState {
  key: string;
}
type TActions = { type: "add_key"; value: string };

const initValue: IGlobalState = {
  key: "",
};

const reducer = (state: IGlobalState, action: TActions) => {
  switch (action.type) {
    case "add_key":
      return { ...state, key: action.value };
    default:
      return state;
  }
};

export const useGlobalContextState = () => {
  const context = useContext(GlobalContextState);
  if (!context) {
    throw new Error("error in useGlobalContextState");
  } else return context;
};

export const useGlobalDispatchState = () => {
  const context = useContext(GlobalDispatchState);
  if (!context) {
    throw new Error("error in useGlobalDispatchState");
  } else return context;
};

const GlobalContext: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initValue);

  return (
    <GlobalContextState.Provider value={state}>
      <GlobalDispatchState.Provider value={dispatch}>
        {children}
      </GlobalDispatchState.Provider>
    </GlobalContextState.Provider>
  );
};

export default GlobalContext;
