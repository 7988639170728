import Success from "components/Success";
import "./successfullyCompleted.scss";

const SuccessfullyCompleted = () => {
  return (
    <div className="success-filled-form">
      <Success width="60px" height="60px" />
      <h2>All Done. Thank you</h2>
    </div>
  );
};

export default SuccessfullyCompleted;
