import "./header.scss";
import logo from "icons/logo.svg";

const Header = () => {
  return (
    <div className="header">
      <img alt="logo" src={logo} />
    </div>
  );
};

export default Header;
