import {
  Button,
  ButtonProps,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import { Box, styled } from "@mui/system";

import { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  fbPixelCodeMap,
  injectFBPixelCodePageViews,
  trackFBLead,
} from "utils/facebookPixelCode";
import {
  amountWillingToInvest,
  currentOccupation,
  financialInstrumentsInterestedIn,
  howSoon,
  whichIndustries,
} from "./constants";

import "./angelInvesment.scss";

interface IFormData {
  name: string;
  email: string;
  mobile: string;
  financialInstrumentsInterestedIn: string[];
  currentOccupation: string;
  amountWillingToInvest: string;
  howSoon: string;
  whichIndustries: string[];
}

const defaultFormValues: IFormData = {
  name: "",
  email: "",
  mobile: "",
  financialInstrumentsInterestedIn: [],
  currentOccupation: "",
  amountWillingToInvest: "",
  howSoon: "",
  whichIndustries: [],
};

interface IButtonProps extends ButtonProps {
  positionCenter?: boolean;
}

const ColorButton = styled(Button)<IButtonProps>(
  ({ positionCenter = true }) => ({
    color: "white",
    fontWeight: "bold",
    display: "flex",
    justifyContent: positionCenter ? "space-around" : "space-between",

    backgroundColor: "#025352",
    "&:hover": {
      backgroundColor: "#02454a",
    },
    "> span:only-child": {
      margin: "0 auto",
    },
  })
);

const AngelInvestment = () => {
  const { course } = useParams<{ course: string }>();

  const navigate = useNavigate();

  const [form, setFormData] = useState(defaultFormValues);
  const [fillRequiredFields, setFillRequiredFields] = useState(false);
  const [partialSubmit, setPartialSubmit] = useState(false);
  const ref = useRef(form);

  const updateData = (field: string, value: string | string[]) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    ref.current = {
      ...ref.current,
      [field]: value,
    };
  };

  const postData = () => {
    fetch(
      "https://webhooks.integrately.com/a/webhooks/00d5b1ba0e8f456f91eb12d0a479e294",
      {
        method: "POST",
        body: JSON.stringify({ ...ref.current, course }),
      }
    );
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    postData();
    navigate("/success");
  };

  const onBlur = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (emailRegex.test(form.email) && form.name.trim().length > 0) {
      setFillRequiredFields(true);
      if (!partialSubmit && form.mobile.length >= 10) {
        setPartialSubmit(true);
        postData();
        trackFBLead();
      }
    }
  };

  useEffect(() => {
    if (course) {
      const code = fbPixelCodeMap[course];
      if (code) {
        injectFBPixelCodePageViews(code);
      }
    }
  }, [course]);

  return (
    <div className="fluent-form">
      <div className="form-header">
        <h2>
          You're just one application away from getting started on your angel
          investing journey
        </h2>
      </div>
      <form className="form" onSubmit={onSubmit}>
        <TextField
          value={form.name}
          onChange={(e) => updateData("name", e.target.value)}
          required
          id="outlined-required"
          label="Name"
          type="text"
          InputProps={{ inputProps: { autocomplete: "name" } }}
        />
        <TextField
          value={form.email}
          onChange={(e) => updateData("email", e.target.value)}
          required
          id="outlined-required"
          label="Email"
          type="email"
        />
        <TextField
          value={form.mobile}
          onChange={(e) => updateData("mobile", e.target.value)}
          required
          id="outlined-required"
          label="Phone/Mobile"
          type="tel"
          onFocus={onBlur}
          onBlur={onBlur}
          InputProps={{ inputProps: { min: 1, autocomplete: "tel" } }}
        />

        {fillRequiredFields && (
          <>
            <FormControl fullWidth>
              <InputLabel id="financial-instruments-interested-in">
                What financial instruments have you invested in? (Select all
                that apply)
              </InputLabel>
              <Select
                labelId="financial-instruments-interested-in"
                multiple
                value={form.financialInstrumentsInterestedIn}
                label="What financial instruments have you invested in? (Select all that apply)"
                onChange={(e: SelectChangeEvent<string[]>) => {
                  const {
                    target: { value },
                  } = e;

                  updateData(
                    "financialInstrumentsInterestedIn",
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {financialInstrumentsInterestedIn.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      <Checkbox
                        checked={
                          form.financialInstrumentsInterestedIn.indexOf(item) >
                          -1
                        }
                      />
                      <ListItemText primary={item} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="current-occupation">
                What is your current occupation?
              </InputLabel>
              <Select
                labelId="current-occupation"
                value={form.currentOccupation}
                label="What is your current occupation?"
                onChange={(e) =>
                  updateData("currentOccupation", e.target.value)
                }
              >
                {currentOccupation.map((item) => {
                  return (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="amount-willing-to-invest">
                How much would you be willing to invest as an angel to start
                your journey?
              </InputLabel>
              <Select
                labelId="amount-willing-to-invest"
                value={form.amountWillingToInvest}
                label="How much would you be willing to invest as an angel to start your journey?"
                onChange={(e) =>
                  updateData("amountWillingToInvest", e.target.value)
                }
              >
                {amountWillingToInvest.map((item) => {
                  return (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="how-soon">
                How soon are you willing to start your journey as an Angel
                Investor?
              </InputLabel>
              <Select
                labelId="how-soon"
                value={form.howSoon}
                label="How soon are you willing to start your journey as an Angel Investor?"
                onChange={(e) => updateData("howSoon", e.target.value)}
              >
                {howSoon.map((item) => {
                  return (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="which-industries">
                Which industries excite you with their investment potential?
                (Select as many)
              </InputLabel>
              <Select
                labelId="which-industries"
                multiple
                value={form.whichIndustries}
                label="Which industries excite you with their investment potential? (Select as many)"
                onChange={(e: SelectChangeEvent<string[]>) => {
                  const {
                    target: { value },
                  } = e;

                  updateData(
                    "whichIndustries",
                    typeof value === "string" ? value.split(",") : value
                  );
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {whichIndustries.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      <Checkbox
                        checked={form.whichIndustries.indexOf(item) > -1}
                      />
                      <ListItemText primary={item} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </>
        )}
        <ColorButton type="submit">Submit</ColorButton>
      </form>
    </div>
  );
};

export default AngelInvestment;
